import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store';
import axios, { AxiosRequestConfig } from 'axios';
import { Profile } from '@/models/core/profile';
import { AuthInfo, LoginRequest, KeycloakToken } from './interfaces';
import { initializeSentry } from '@/util/sentry';
import { globalStore } from '../global';
import { Dictionary } from '@/util/interfaces';
import { addCookie, getCookie } from '@/util/cookie';

export interface AuthState {
  isLoggedIn: boolean;
  profile: Profile | null;
  axiosConfig: AxiosRequestConfig;
}

/**
 * dynamic: true -> register module into store with name
 */
@Module({ dynamic: true, store: store, name: 'auth', namespaced: true })
class Auth extends VuexModule implements AuthState {
  isLoggedIn = false;
  profile: Profile | null = null;
  axiosConfig: AxiosRequestConfig = {
    headers: {},
    withCredentials: true,
    // adds token from cookie to header
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFTOKEN',
  };
  csrfHeaders: Dictionary<string> = {};

  @Mutation
  setIsLoggedIn(value: boolean): void {
    this.isLoggedIn = value;
  }

  @Mutation
  setProfile(value: Profile | null): void {
    this.profile = value;
  }

  @Mutation
  setAxiosConfig(value: AxiosRequestConfig): void {
    this.axiosConfig = value;
  }

  @Mutation
  setCsrfHeaders(value: Dictionary<string>): void {
    this.csrfHeaders = value;
  }

  @Action
  async initialize(): Promise<void> {
    const authInfoResponse = await axios.get<AuthInfo>('/api/v1/auth/info');
    const authInfo = authInfoResponse.data;
    if (authInfo.sentry_dsn) {
      initializeSentry(
        authInfo.sentry_dsn,
        authInfo.environment,
        globalStore.frontendVersion,
      );
    }
    if (authInfo.id) {
      await this.initializeProfile(authInfo.id);
      await this.initializeCsrfHeaders();
    }
    this.setIsLoggedIn(authInfo.authenticated);
  }

  @Action
  async initializeCsrfHeaders(): Promise<void> {
    const csrfToken = getCookie('csrftoken');
    if (csrfToken) {
      this.setCsrfHeaders({
        'X-CSRFTOKEN': csrfToken,
      });
    }
  }

  @Action
  async initializeProfile(id: string): Promise<void> {
    const profileResponse = await axios.get<Profile>(`/api/v1/profile/${id}/`);
    this.setProfile(profileResponse.data);
  }

  @Action
  async logIn({ username, password }: LoginRequest): Promise<void> {
    const authInfoResponse = await axios.post<AuthInfo>('/api/v1/auth/login', {
      username,
      password,
    });
    const authInfo = authInfoResponse.data;
    const token_request_params = new URLSearchParams({
      grant_type: 'password',
      username: username,
      password: password,
      client_id: 'keycloak-devicehub',
      client_secret: 'PWMGGIg73jMTx3pllGj06j3mhUpF4Khr',
      scope: 'openid',
    });
    const keycloakTokenResponse = await axios.post<KeycloakToken>(
      '/keycloak/realms/study/protocol/openid-connect/token',
      token_request_params.toString(),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'accept': 'application/json',
        },
      },
    );
    const keycloakToken = keycloakTokenResponse.data;
    if (authInfo.id) {
      await this.initializeProfile(authInfo.id);
      await this.initializeCsrfHeaders();
    }
    this.setIsLoggedIn(authInfo.authenticated);
    addCookie(
      'keycloakAccessToken',
      keycloakToken.access_token,
      keycloakToken.expires_in,
    );
  }

  @Action
  async logOut(): Promise<void> {
    await axios.post('/api/v1/auth/logout', {}, this.axiosConfig);
    store.commit('global/reset');
    this.setIsLoggedIn(false);
    this.setProfile(null);
    this.setCsrfHeaders({});
  }
}

export const authStore = getModule(Auth);
