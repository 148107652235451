import { defineStore } from 'pinia';
import { Dictionary } from '@/util/interfaces';
import { AppApi, Configuration } from '@/generated/api/studyDataExport';

export interface StudyDataExportApiState {
  basePath: string;
  baseOptions: {
    headers: Dictionary<string>;
  };
}

export const useStudyDataExportApi = defineStore({
  id: 'studyDataExportApi',
  state: (): StudyDataExportApiState => ({
    // will be set to "devicehub_study_data_export_base_path" from DMS client app setting "config"
    basePath: '',
    baseOptions: {
      headers: {},
    },
  }),

  getters: {
    configuration(): Configuration {
      return new Configuration({
        basePath: this.basePath,
        baseOptions: this.baseOptions,
      });
    },
    appApi(): AppApi {
      return new AppApi(this.configuration);
    },
  },

  actions: {
    setBasePath(basePath: string) {
      this.basePath = basePath;
    },

    setHeaders(headers: Dictionary<string>) {
      this.baseOptions.headers = headers;
    },
  },
});
