/* tslint:disable */
/* eslint-disable */
/**
 * DeviceHub Study Data Export
 * DeviceHub Study Data Export Package
 *
 * The version of the OpenAPI document: 2021.11.6.dev4+2412ae9
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ExportStatus
 */
export interface ExportStatus {
    /**
     * 
     * @type {boolean}
     * @memberof ExportStatus
     */
    completed: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExportStatus
     */
    last_page: number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface InfoDto
 */
export interface InfoDto {
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    version: string;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}

/**
 * AppApi - axios parameter creator
 * @export
 */
export const AppApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDownloadGet: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('downloadDownloadGet', 'jobId', jobId)
            const localVarPath = `/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['job_id'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Export Status
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStatusExportStatusGet: async (jobId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'jobId' is not null or undefined
            assertParamExists('exportStatusExportStatusGet', 'jobId', jobId)
            const localVarPath = `/export-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['job_id'] = jobId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger Data Export
         * @param {string} start 
         * @param {string} end 
         * @param {string} deviceUuid 
         * @param {string} streamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDataExportTriggerDataExportGet: async (start: string, end: string, deviceUuid: string, streamId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'start' is not null or undefined
            assertParamExists('triggerDataExportTriggerDataExportGet', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('triggerDataExportTriggerDataExportGet', 'end', end)
            // verify required parameter 'deviceUuid' is not null or undefined
            assertParamExists('triggerDataExportTriggerDataExportGet', 'deviceUuid', deviceUuid)
            // verify required parameter 'streamId' is not null or undefined
            assertParamExists('triggerDataExportTriggerDataExportGet', 'streamId', streamId)
            const localVarPath = `/trigger-data-export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (deviceUuid !== undefined) {
                localVarQueryParameter['device_uuid'] = deviceUuid;
            }

            if (streamId !== undefined) {
                localVarQueryParameter['stream_id'] = streamId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AppApi - functional programming interface
 * @export
 */
export const AppApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AppApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDownloadGet(jobId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDownloadGet(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Export Status
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportStatusExportStatusGet(jobId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportStatusExportStatusGet(jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger Data Export
         * @param {string} start 
         * @param {string} end 
         * @param {string} deviceUuid 
         * @param {string} streamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async triggerDataExportTriggerDataExportGet(start: string, end: string, deviceUuid: string, streamId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.triggerDataExportTriggerDataExportGet(start, end, deviceUuid, streamId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AppApi - factory interface
 * @export
 */
export const AppApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AppApiFp(configuration)
    return {
        /**
         * 
         * @summary Download
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDownloadGet(jobId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadDownloadGet(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Export Status
         * @param {string} jobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStatusExportStatusGet(jobId: string, options?: any): AxiosPromise<ExportStatus> {
            return localVarFp.exportStatusExportStatusGet(jobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger Data Export
         * @param {string} start 
         * @param {string} end 
         * @param {string} deviceUuid 
         * @param {string} streamId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        triggerDataExportTriggerDataExportGet(start: string, end: string, deviceUuid: string, streamId: string, options?: any): AxiosPromise<string> {
            return localVarFp.triggerDataExportTriggerDataExportGet(start, end, deviceUuid, streamId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AppApi - interface
 * @export
 * @interface AppApi
 */
export interface AppApiInterface {
    /**
     * 
     * @summary Download
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApiInterface
     */
    downloadDownloadGet(jobId: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary Export Status
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApiInterface
     */
    exportStatusExportStatusGet(jobId: string, options?: any): AxiosPromise<ExportStatus>;

    /**
     * 
     * @summary Trigger Data Export
     * @param {string} start 
     * @param {string} end 
     * @param {string} deviceUuid 
     * @param {string} streamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApiInterface
     */
    triggerDataExportTriggerDataExportGet(start: string, end: string, deviceUuid: string, streamId: string, options?: any): AxiosPromise<string>;

}

/**
 * AppApi - object-oriented interface
 * @export
 * @class AppApi
 * @extends {BaseAPI}
 */
export class AppApi extends BaseAPI implements AppApiInterface {
    /**
     * 
     * @summary Download
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public downloadDownloadGet(jobId: string, options?: any) {
        return AppApiFp(this.configuration).downloadDownloadGet(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Export Status
     * @param {string} jobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public exportStatusExportStatusGet(jobId: string, options?: any) {
        return AppApiFp(this.configuration).exportStatusExportStatusGet(jobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger Data Export
     * @param {string} start 
     * @param {string} end 
     * @param {string} deviceUuid 
     * @param {string} streamId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AppApi
     */
    public triggerDataExportTriggerDataExportGet(start: string, end: string, deviceUuid: string, streamId: string, options?: any) {
        return AppApiFp(this.configuration).triggerDataExportTriggerDataExportGet(start, end, deviceUuid, streamId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheusRouteMetricsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoInfoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.infoInfoGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prometheusRouteMetricsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prometheusRouteMetricsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoInfoGet(options?: any): AxiosPromise<InfoDto> {
            return localVarFp.infoInfoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prometheus Route
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prometheusRouteMetricsGet(options?: any): AxiosPromise<any> {
            return localVarFp.prometheusRouteMetricsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    infoInfoGet(options?: any): AxiosPromise<InfoDto>;

    /**
     * 
     * @summary Prometheus Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    prometheusRouteMetricsGet(options?: any): AxiosPromise<any>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoInfoGet(options?: any) {
        return DefaultApiFp(this.configuration).infoInfoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prometheus Route
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public prometheusRouteMetricsGet(options?: any) {
        return DefaultApiFp(this.configuration).prometheusRouteMetricsGet(options).then((request) => request(this.axios, this.basePath));
    }
}


